<template>
  <div style="background-color: #fff;height: 3.646vw;line-height: 3.646vw;text-align: right;box-sizing: border-box;display: inline-block;font-size: 1.04vw;display: block;padding: 0 12.5vw;box-sizing: border-box;margin: 0 auto;position: fixed;top: 0;width: 100vw;z-index: 1;">
    <img :src="homepage_logo" style="width: 19.58vw;height: 2.97vw;display: inline-block;vertical-align: top;float: left;margin-top: 0.3vw;"/>
    <a :class="[ 'headMenu', isHome ]" href="#/">首页</a>
    <a :class="[ 'headMenu', isProducts ]" href="#/products">产品介绍</a>
    <a :class="[ 'headMenu', isCompany ]" href="#/company">企业资质</a>
    <a :class="[ 'headMenu', isContactus ]" href="#/contactus">联系我们</a>
  </div>
  <div style="width: 100%;height: 3.646vw;"></div>
  <component :is="currentView" :fade_class="fade_class" />
  <div style="width: 100%;height: 15.625vw;background-color: #2c445c;margin-top: 5.21vw;">
    <div style="width: 75vw;margin: 0 auto;">
      <div class="footer_menu">
        <div class="footer_menu_title">首页</div>
        <a href="#/company"><div class="footer_menu_url">公司介绍</div></a>
        <a href="#/company"><div class="footer_menu_url">企业文化</div></a>
      </div>
      <div class="footer_menu">
        <div class="footer_menu_title">产品介绍</div>
        <a href="#/products"><div class="footer_menu_url">ERP+MES系统</div></a>
        <a href="#/products"><div class="footer_menu_url">美膳APP</div></a>
        <a href="#/products"><div class="footer_menu_url">公交餐饮APP</div></a>
        <a href="#/products"><div class="footer_menu_url">中百团膳</div></a>
        <a href="#/products"><div class="footer_menu_url">唐肴鲜生</div></a>
      </div>
      <div class="footer_menu">
        <div class="footer_menu_title">企业资质</div>
        <a href="#/company"><div class="footer_menu_url">电信增值业务许可icp证书</div></a>
        <a href="#/company"><div class="footer_menu_url">计算机软件著作权</div></a>
      </div>
      <div style="float: right;display: inline-block;box-sizing: border-box;padding: 1.56vw 0;vertical-align: top;">
        <div class="footer_menu_title">联系我们</div>
        <div class="footer_menu_contact"><img class="footer_menu_icon" :src="bottom_landline"/>027-82288499</div>
        <div class="footer_menu_contact"><img class="footer_menu_icon" :src="bottom_tel"/>18162532466</div>
        <div class="footer_menu_contact"><img class="footer_menu_icon" :src="bottom_mail"/>wangxuewu@xrctech.cn</div>
      </div>
    </div>
  </div>
  <div style="text-align: center;background-color: #1e3348;color: #8e8e8e;height: 2.71vw;line-height: 2.71vw;font-size: 0.94vw;">备案号: 鄂ICP备202100706号-1</div>
</template>

<script>
import { ref } from 'vue'
import home from './components/home.vue'
import products from './components/products.vue'
import company from './components/company.vue'
import contactus from './components/contactus.vue'
const routes = {
  '/': home,
  '/products': products,
  '/company': company,
  '/contactus': contactus
}
const currentPath = ref(window.location.hash)

export default {
  name: 'homePage',
  components: {
  },
  data () {
    return {
      homepage_logo: require('@/assets/homepage_logo.png'),
      bottom_mail: require('@/assets/bottom_mail.png'),
      bottom_tel: require('@/assets/bottom_tel.png'),
      bottom_landline: require('@/assets/bottom_landline.png'),
      fade_class: 'fade_in'
    }
  },
  computed: {
    currentView() { return routes[currentPath.value.slice(1) || '/'] || home },
    isHome() { return { active: !currentPath.value || currentPath.value == '#/' } },
    isProducts() { return { active: currentPath.value == '#/products' } },
    isCompany() { return { active: currentPath.value == '#/company' } },
    isContactus() { return { active: currentPath.value == '#/contactus' } }
  },
  created() {
    let _this = this
    window.addEventListener('hashchange', () => {
      _this.fade_class = 'fade_out'
      setTimeout(()=>{
        currentPath.value = window.location.hash
        setTimeout(()=>{
          _this.fade_class = 'fade_in'
        },100)
      },100)
    })
  },
  beforeUnmount(){
    let _this = this
    window.removeEventListener('hashchange', () => {
      _this.fade_class = 'fade_out'
      setTimeout(()=>{
        currentPath.value = window.location.hash
        setTimeout(()=>{
          _this.fade_class = 'fade_in'
        },100)
      },100)
    })
  },
}
</script>

<style>
  body{
    margin: 0;
    padding: 0;
    font-size: 14px;
    font-family: "Microsoft YaHei";
  }
  .page{
    transition: all ease .1s;
    opacity: 0;
  }
  .page.fade_in{
    opacity: 1;
  }
  .page.fade_out{
    opacity: 0;
  }
  .headMenu{
    text-decoration: none;
    color: #333;
    padding: 0 1.56vw;
    transition: all ease .3s;
    line-height: 3.646vw;
    display: inline-block;
    position: relative;
  }
  .headMenu:hover{
    background-color: #8dc2ff;
    color: #fff;
  }
  .headMenu.active{
    color: #5688ef;
  }
  .headMenu::after{
    content: '';
    height: 2px;
    border-radius: 1px;
    position: absolute;
    bottom: 0;
    width: calc(100% - 2.6vw);
    background: linear-gradient(to right, #0072f5, #eb94df);
    left: 1.3vw;
    opacity: 0;
    transition: all ease .3s;
  }
  .headMenu.active::after{
    opacity: 1;
  }
  .footer_menu{
    display: inline-block;
    height: 15.625vw;
    box-sizing: border-box;
    padding: 1.56vw 8vw 1.56vw 2.19vw;
    position: relative;
    vertical-align: top;
  }
  .footer_menu::before{
    content: '';
    width: 1px;
    height: 11.875vw;
    background-color: #fff;
    position: absolute;
    left: 12px;
    top: 1.875vw;
  }
  .footer_menu::after{
    content: '';
    width: 3px;
    height: 1.04vw;
    background-color: #fff;
    position: absolute;
    left: 11px;
    top: 1.875vw;
  }
  .footer_menu_title{
    font-family: "Microsoft YaHei";
    font-size: 1.146vw;
    color: #fff;
    padding-bottom: 6px;
  }
  .footer_menu_url{
    margin-top: 0.625vw;
    font-family: "Microsoft YaHei";
    font-size: 1.04vw;
    color: #d7d2d2;
    cursor: pointer;
  }
  .footer_menu_url:hover{
    text-decoration: underline;
  }
  .footer_menu_contact{
    font-family: "Microsoft YaHei";
    font-size: 1.146vw;
    color: #fff;
    line-height: 2.29vw;
    margin-top: 0.83vw;
  }
  .footer_menu_icon{
    width: 2.29vw;
    height: 2.29vw;
    display: inline-block;
    vertical-align: top;
  }
</style>
