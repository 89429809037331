<template>
  <div :class="[fade_class,'page']">
    <img :src="homepage_banner" style="width: 100vw;" />
    <div style="width: 75vw;margin: 4.69vw auto 0;position: relative;">
      <div style="width: 37.5vw;display: inline-block;">
        <div style="font-size: 1.67vw;color: #5688ff;font-weight: 500;margin-bottom: 50px;">武汉美膳亿禾科技有限公司</div>
        <div style="font-size: 1.04vw;color: #666;padding-right: 3.646vw;">武汉美膳亿禾科技有限公司是一家集互联网软件平合项目开发和运营为一体的信息科技公司。企业通过推进“平台、技术、云服务”这三大核心板块，服务包括湖北农发鲜科集团、中百集团、郑州公交餐饮公司等多家大型上市和集团企业以及数十万个人客户，提供包括团生产管理ERP+MES系统、团膳订餐亚台、个人订餐平台、供应链系统在内的全方位科技信息服务。致力于成为全国领先的食品加工制造行业SAAS软件系统服务商</div>
        <div style="position: absolute;bottom: 0;height: 5.21vw;width: 37.5vw;background-color: #5688ff;">
          <a href="#/company"><img :src="homepage_aboutmore" style="margin-top: 1.52vw;margin-left: 1.52vw;height: 2.08vw;" /></a>
        </div>
      </div>
      <img :src="homepage_occupy_bitmap" style="width: 37.5vw;vertical-align: top;">
    </div>
    <img :src="homepage_culture" style="margin: 0 auto;display: block;width: 16.56vw;" />
    <div style="width: 37.5vw;margin: 0 auto 60px;font-size: 1.04vw;color: #6f6f6f;text-align: center;">专注于移动互联网和食品加工SAAS，坚持以技术创新为核心，以用户需求为导向，关注用户体验，市场发展方向，为企业客户提供最专业的移动应用和SAAS产品的解决方案</div>
    <div style="width: 75vw;margin: 0 auto;">
      <div class="home_banner homepage_cultyre_service">
        <div class="home_banner_title">服务为先</div>
        <div class="home_banner_subtitle">Service First</div>
        <div class="home_banner_footer">以优质服务领航企业发展</div>
      </div>
      <div class="home_banner homepage_cultyre_technology" style="margin-left: 2.08vw;">
        <div class="home_banner_title">技术为本</div>
        <div class="home_banner_subtitle">Technology Oriented</div>
        <div class="home_banner_footer">以成熟技术推动项目前进</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'home-',
  props: {
    fade_class: String
  },
  data () {
    return {
      homepage_banner: require('@/assets/homepage_banner.png'),
      homepage_occupy_bitmap: require('@/assets/homepage_occupy_bitmap.png'),
      homepage_aboutmore: require('@/assets/homepage_aboutmore.png'),
      homepage_culture: require('@/assets/homepage_culture.png')
    }
  }
}
</script>

<style scoped>
  .home_banner{
    width: 36.46vw;
    height: 20.52vw;
    display: inline-block;
    position: relative;
    vertical-align: top;
  }
  .homepage_cultyre_service{
    background-image: url('@/assets/homepage_cultyre_service.png');
  }
  .homepage_cultyre_technology{
    background-image: url('@/assets/homepage_cultyre_technology.png');
  }
  .home_banner_title{
    margin: 4vw 2vw 0;
    font-size: 1.77vw;
    font-family: "Microsoft YaHei";
    color: #fff;
    text-shadow: 0 2px 2px rgba(0, 0, 0, 1);
  }
  .home_banner_subtitle{
    margin: 0.125vw 2vw 0;
    font-size: 1.354vw;
    font-family: "Microsoft YaHei";
    color: #fff;
    text-shadow: 0 2px 2px rgba(0, 0, 0, 1);
  }
  .home_banner_footer{
    position: absolute;
    bottom: 0;
    height: 3.125vw;
    background-color: rgba(0, 0, 0, .2);
    font-size: 1.04vw;
    line-height: 3.125vw;
    font-family: "Microsoft YaHei";
    color: #fff;
    width: 100%;
    padding-left: 2vw;
    box-sizing: border-box;
  }
</style>
