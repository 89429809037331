<template>
  <div>
    <img :src="require('@/assets/lxwm/contact_banner.png')" style="width: 100vw;" />
    <div style="width: 80vw;margin: auto;margin-top: -50px">
      <img :src="require('@/assets/lxwm/contact_information.png')"  style="width: 80vw"/>
    </div>
    <div style="font-size: 20px;color: #333333;margin-top: 80px;margin-left: 10vw">地图位置</div>
    <div style="width: 80vw;margin: auto;margin-top: 30px">
      <img :src="require('@/assets/lxwm/contact_map.png')"  style="width: 80vw"/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'contactus-',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
